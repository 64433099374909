/* eslint-disable sonarjs/no-duplicate-string */
// istanbul ignore file
import { environment } from '@environment';
import { UserOverview } from '@wizbii/utils/models';
import {
  TrackingConfig,
  createAwinConfig,
  createBingPixelConfig,
  createCriteoPixelConfig,
  createFacebookPixelConfig,
  createGoogleAnalyticsConfig,
  createGoogleRetargetingConfig,
  createHotjarConfig,
  createNeuvooPixelConfig,
  createOutbrainPixelConfig,
  createPinterestPixelConfig,
  createSnapchatPixelConfig,
  createTaboolaPixelConfig,
  createTiktokPixelConfig,
  createTwitterPixelConfig,
  createWizbiiAnalyticsConfig,
  createYahooPixelConfig,
} from '@wizbii/utils/tracking';

export const trackingConfig = (appId: string, browser: boolean, user?: UserOverview): TrackingConfig => ({
  informationGroupKeys: ['technical'],
  uuidSetterDomain: browser ? '/_api/analytiks-api' : 'http://analytiks-api',
  envFqdn: environment.domain,

  consentWidget: {
    version: environment.platform !== 'prod' ? 'staging' : '',
    apiUrl: browser ? '/_api/analytiks-api' : 'http://analytiks-api',
    apiDomain: environment.domain,
    enableTermsModal: true,
    locale: environment.lang,
    productId: appId,
    defaultValue: false,
    productLabel: 'WIZBII',
    labels: {},
    rejectAll: true,
    consentUrl: browser ? '/_api/consent-api' : 'http://consent-api',
    contentUrl: browser ? '/_api/content-api' : 'http://content-api',
    accountUrl: browser ? '/_api/account-api' : 'http://account-api',
  },
  trackers: {
    analytics_wizbii: createWizbiiAnalyticsConfig(
      environment.analytics.waTrackingId,
      browser ? '/_api/analytiks-api' : 'http://analytiks-api'
    ),
    analytics_google: createGoogleAnalyticsConfig(environment.analytics.gaTrackingId),
    marketing_google: createGoogleRetargetingConfig(environment.analytics.gaConversionId[environment.locale]),
    connect_facebook: {
      scriptUrl: `https://connect.facebook.net/${environment.locale}/sdk.js#xfbml=1&version=${environment.facebookLogin.version}&appId=${environment.facebookLogin.appId}&autoLogAppEvents=1`,
      prepareQueue: () => {},
    },
    connect_google: {
      scriptUrl: `https://accounts.google.com/gsi/client`,
      prepareQueue: () => {},
    },
    connect_apple: {
      scriptUrl: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
      prepareQueue: () => {},
    },
    ...(environment.facebookPixel?.[environment.locale]?.[appId]
      ? {
          analytics_facebook_pixel: createFacebookPixelConfig({
            appId: environment.facebookPixel[environment.locale][appId].id,
            version: environment.facebookPixel[environment.locale][appId].version,
            locale: environment.locale,
            userEmail: user?.username,
          }),
        }
      : {}),
    ...(environment.snapchatPixel?.[environment.locale]?.[appId]
      ? {
          analytics_snapchat_pixel: createSnapchatPixelConfig({
            appId: environment.snapchatPixel[environment.locale][appId],
            userEmail: user?.username,
          }),
        }
      : {}),
    ...(environment.tiktok?.[environment.locale]?.[appId]
      ? {
          analytics_tiktok_pixel: createTiktokPixelConfig(
            environment.tiktok[environment.locale][appId],
            user?.username
          ),
        }
      : {}),
    ...(environment.bingPixel?.[environment.locale]?.[appId]
      ? {
          analytics_bing: createBingPixelConfig(environment.bingPixel[environment.locale][appId]),
        }
      : {}),
    ...(environment.neuvoo[appId]
      ? {
          marketing_neuvoo: createNeuvooPixelConfig(environment.neuvoo[appId]),
        }
      : {}),
    analytics_hotjar: createHotjarConfig({
      hjid: environment.analytics.hotjar.id,
      hjsv: environment.analytics.hotjar.version,
    }),
    analytics_yahoo: createYahooPixelConfig(environment.analytics.yahoo.projectId, environment.analytics.yahoo.pixelId),
    analytics_twitter: createTwitterPixelConfig(environment.analytics.twitter),
    analytics_awin: createAwinConfig({ isTestMode: !environment.isProdBuild, merchantId: environment.awin.merchantId }),
    analytics_pinterest: createPinterestPixelConfig({
      pixelId: environment.analytics.pinterest.pixelId,
      pixelVersion: environment.analytics.pinterest.pixelVersion,
      email: user?.username,
    }),
    analytics_outbrain: createOutbrainPixelConfig({
      pixelId: environment.analytics.outbrain.pixelId,
      pixelVersion: environment.analytics.outbrain.pixelVersion,
    }),
    analytics_criteo: createCriteoPixelConfig({
      appId: environment.analytics.criteo.appId,
      userEmail: user?.username,
      productId: appId,
    }),
    analytics_taboola: createTaboolaPixelConfig(environment.analytics.taboola.appId),
  },
});
